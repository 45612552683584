<template>
  <div>
    <nav>
      <div>

      </div>

      <div class="user-div">
        <a href="#" @click.prevent="userImgClicked">

          <div class="not-login-pic" v-if="!hasToken">
            <icon name="user"></icon>
          </div>

          <div class="login-pic" v-else>
            <img :src="userPicUrl" alt="">
          </div>
        </a>
      </div>

      <b-sidebar class="user-side-bar" v-model="userSidebarShow" shadow right backdrop no-header>
        <ul>
          <li>
            <label for="">
              個人
            </label>
          </li>
          <li>
            <a href="#">
              會員帳號設定
            </a>
          </li>
          <li>
            <a class="logout" href="#" @click.prevent="userLogout">
              登出
            </a>
          </li>
        </ul>
      </b-sidebar>
    </nav>
  </div>
</template>

<script>
import commonBusyAndErrorHandling from "@/assets/js/commonBusyAndErrorHandling";
import { mapActions, mapGetters } from 'vuex';

export default {
  mixins: [commonBusyAndErrorHandling],
  name: 'ShopTopNav',
  data() {
    return {
      userSidebarShow: false,
      user: {
        picUrl: '',
      },
    };
  },
  props: {
    
  },
  components: {
  },
  computed: {
		... mapGetters(['hasToken']),
    userPicUrl() {
      if (this.hasToken) {
        return this.user.picUrl;
      }
      return '';
    }
	},
  watch: {
    
  },
  mounted() {
    if (this.hasToken) {
      this.handleAsyncTask(async () => {
        const data = await this.$store.dispatch('api/getUserDataPromise');
        this.user = Object.assign({}, this.user, data);
      });
    }
	},
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'updateCloth', 'logout']),
    ...mapActions('loopii', ['redirectToLogin', 'setRedirect', 'getRedirect']),
    userImgClicked() {
      if (this.hasToken) {
        this.userSidebarShow = !this.userSidebarShow;
      } else {
        this.redirectToLogin(this.$route);
      }
    },
    userLogout() {
      try {
        const r = window.confirm('確定登出嗎?');
        if (r) {
          this.logout();
          this.userSidebarShow = false;
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
nav {
  background-color: #fff;
  border-bottom: solid 1px $color-black-300;
  padding: .5rem 3rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .user-div {
    >a {
      position: relative;
      display: block;
      width: 36px;
      height: 36px;
      overflow: hidden;
      border-radius: 50%;
      .not-login-pic {
        background-color: $color-image-background;
        padding: 6px;
        >svg {
          width: 24px;
          height: 24px;
          display: block;
        }
      }
      .login-pic {
        >img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .user-side-bar {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        label {
          display: block;
          padding: 1rem 1.5rem;
          color: $typography-secondary-default;
        }

        a {
          display: block;
          border-bottom: solid 1px $color-border;
          padding: 1rem 1.5rem;
          font-weight: bold;
          color: $typography-primary-default;
          &:hover {
            text-decoration: none;
          }
        }

        a.logout {
          margin-top: 1rem;
          color: $typography-primary-error;
          font-weight: normal;
        }
      }
    }
  }

  @include smaller-than-large() {
    padding: .5rem 1rem;
  }
}
</style>
<template>
  <div>
    <footer>
      <div class="container">
        <ul>
          <li>
            <a href="#" @click.prevent="buyFlowClicked">購物流程</a>
          </li>
          <li>
            <a href="#" @click.prevent="policyClicked">隱私權聲明</a>
          </li>
          <li>
            <a href="#" @click.prevent="exchangePolicyClicked">退換貨政策</a>
          </li>
        </ul>

        <p class="text-secondary text-center mb-2">
          Copyright © 2025 Link Link Co-Creation Co., Ltd.. All Rights Reserved
        </p>

        <p class="text-secondary text-center mb-0">
          Powered by Loopii 寄賣通 Version 2.1
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'ShopBottomFooter',
  data() {
    return {
    };
  },
  props: {
    
  },
  components: {
  },
  computed: {
	},
  watch: {
    
  },
  mounted() {
    
	},
  methods: {
    buyFlowClicked() {
      this.$store.dispatch('setBuyFlowShow', true);
    },
    policyClicked() {
      this.$store.dispatch('setPolicyShow', true);
    },
    exchangePolicyClicked() {
      this.$store.dispatch('setExchangeShow', true);
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
footer {
  padding: 2.5rem 0;
  background-color: $color-gray-light;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: .6rem;
    li {
      &:not(:first-child) {
        &::before {
          content: '｜';
        }
      }
      >a {
        color: #000;
      }
    }
  }
}
</style>
<template>
  <div class="shop-main">
    <div class="content-flex" v-if="inited">
      <div class="top-nav">
        <ShopTopNav></ShopTopNav>
      </div>
  
      <div class="center-content">
        <section class="intro">
          <div class="container">
            <div class="banner">
              <div>
                <img :src="baDetail.profileBanner" :alt="baDetail.name">
              </div>
            </div>
  
            <div class="intro-title">
              <h2>{{ baDetail.name }}</h2>
              <h3>{{ baDetail.desc }}</h3>
            </div>
          </div>
        </section>

        <section class="tab-links">
          <div class="container">
            <ul>
              <li>
                <router-link :to="{name: 'LoopiiShopEvents'}" class="tab-link">
                  <div>
                    <p>選購服務</p>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="{name: 'LoopiiShopOrders'}" class="tab-link">
                  <div>
                    <p>服務紀錄</p>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="{name: 'LoopiiShopWallet'}" class="tab-link">
                  <div>
                    <p>回饋金管理</p>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
        </section>

        <router-view :baSerial="baDetail.serial"></router-view>
      </div>
  
      <div class="bottom-footer">
        <ShopBottomFooter></ShopBottomFooter>
      </div>
    </div>

    
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ShopTopNav from './ShopTopNav.vue';
import ShopBottomFooter from './ShopBottomFooter.vue';
import commonBusyAndErrorHandling from "@/assets/js/commonBusyAndErrorHandling";

export default {
  mixins: [commonBusyAndErrorHandling],
  name: 'ShopMain',
  data() {
    return {
      baDetail: {},
      inited: false,
    };
  },
  props: {
    
  },
  components: {
    ShopTopNav,
    ShopBottomFooter,
  },
  computed: {
		
	},
  watch: {
    
  },
  mounted() {
    try {
      const storeId = this.$route.params.storeId;
      this.handleAsyncTask(async () => {
        const data = await this.$store.dispatch('api/getBusinessAccountDetailPromise', storeId);
        this.baDetail = Object.assign({}, data);
        this.inited = true;
      });
    } catch (error) {
      console.error(error);
      this.showMsgModal(error);
    }
	},
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.shop-main {
  .content-flex {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .top-nav {
      flex: 0 0 auto;
    }
  
    .center-content {
      flex: 1 1;
      .intro {
        .banner {
          padding: 1.5rem 0;
          position: relative;
          >div {
            position: relative;
            padding-top: 51%;
            >img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }
        }
    
        .intro-title {
          padding: .75rem 0;
          >h2 {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: .75rem;
          }
    
          >h3 {
            font-size: .8rem;
            font-weight: 400;
            margin: 0;
          }
        }
      }

      .tab-links {
        padding: .5rem 0;
        ul {
          padding: 0;
          margin: 0;
          list-style: none;
          display: flex;
          align-items: center;
          li {
            .tab-link {
              display: block;
              padding: 0 .5rem;
              font-weight: 600;
              color: #000;
              border-bottom: solid 2px transparent;
              &:hover {
                text-decoration: none;
              }
              &.router-link-active {
                border-bottom: solid 2px $typography-interactive-primary;
              }
              >div {
                >p {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }

    .bottom-footer {
      flex: 0 0 auto;

    }
  }
}
</style>